import "./successfulSale.page.scss";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import ReactToPrint from 'react-to-print';
// import JsBarcode from 'jsbarcode';

import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import { SideMenuContext } from "../../../context/SideMenuContext";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import completedIcon from "../../../icons/sale-completed.png";
import pendingIcon from "../../../icons/sale-pending.png";

import crossIcon from "../../../icons/cross-white.png";
import printIconDark from "../../../icons/printer-dark.png";

import saveIcon from "../../../icons/check-dark.png";

import adjustmentIconDark from '../../../icons/adjustment-dark.png';
import adjustmentIconLight from '../../../icons/adjustment-light.png';

import xCancelDark from "../../../icons/cancel-dark.png";
import xCancelLight from "../../../icons/cancel-light.png";

import sendIconDark from "../../../icons/send-dark.png";
import sendIconLight from "../../../icons/send-light.png";

import downloadIconDark from "../../../icons/download-dark.png";
import copyIconDark from "../../../icons/copy.png";
import emailIconDark from "../../../icons/email-dark.png";
import whatsAppIconDark from "../../../icons/whatsapp-icon-dark.png";

import copyIconLight from "../../../icons/copyLight.png";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";
import Spinner from "../../../components/spinner/spinner";
import firebase from "../../../firebase";
import SaleReceipt from "../../../receipts/saleReceipt";

const SuccessfulSalePage = () => {
    const { appTheme, appLanguage, isTouchEnabled, formatRationalNumber, useMode } = useContext(AppContext);
    const { accountData, accessTo, shift, userSettings, setUserSettings, saveKeyInIndexedDB, managerAccess } = useContext(AuthContext);
    const { desktopView, windowDimension } = useContext(SideMenuContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);

    let settingsRef = useRef();
    let shareRef = useRef();

    const navigate = useNavigate();
    const location = useLocation();

    const [sale, setSale] = useState(null);

    const [receiptData, setReceiptData] = useState(null);

    const [loadingSettings, setLoadingSettings] = useState(false);

    const [showSave, setShowSave] = useState(false);

    const [settingsIcon, setSettingsIcon] = useState(appTheme.dark ? adjustmentIconDark : adjustmentIconLight);

    const [shareIcon, setShareIcon] = useState(appTheme.dark ? sendIconDark : sendIconLight);

    const [dropDownSettingsOpen, setDropDownSettingsOpen] = useState(false);

    const [dropDownShareOpen, setDropDownShareOpen] = useState(false);

    useEffect(() => {
        if (dropDownSettingsOpen) {
            setSettingsIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setSettingsIcon(appTheme.dark ? adjustmentIconDark : adjustmentIconLight);
        }
    }, [dropDownSettingsOpen, appTheme]);

    useEffect(() => {
        if (dropDownShareOpen) {
            setShareIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setShareIcon(appTheme.dark ? sendIconDark : sendIconLight);
        }
    }, [dropDownShareOpen, appTheme]);

    const onClickSettingsButton = () => {
        dropDownSettingsOpen ? setDropDownSettingsOpen(false) : setDropDownSettingsOpen(true);
    }

    const onClickShareButton = () => {
        dropDownShareOpen ? setDropDownShareOpen(false) : setDropDownShareOpen(true);
    }

    useEffect(() => {
        if (dropDownSettingsOpen && settingsRef) {
            if (settingsRef.current) {
                let handler = (e) => {
                    if (!settingsRef.current.contains(e.target)) {
                        // if (!pickerDateActive && !alertActive) {
                        setDropDownSettingsOpen(false);
                        // }
                    };
                };
                document.addEventListener("mousedown", handler);
                return () => {
                    document.removeEventListener("mousedown", handler);
                }
            }
        }
    });

    useEffect(() => {
        if (dropDownShareOpen && shareRef) {
            if (shareRef.current) {
                let handler = (e) => {
                    if (!shareRef.current.contains(e.target)) {
                        setDropDownShareOpen(false);
                    };
                };
                document.addEventListener("mousedown", handler);
                return () => {
                    document.removeEventListener("mousedown", handler);
                }
            }
        }
    });

    const [tempSettings, setTempSittings] = useState(userSettings ? {
        "invoiveSize": userSettings.invoiveSize,
    } : null);

    useEffect(() => {
        setTempSittings(userSettings ? {
            "invoiveSize": userSettings.invoiveSize,
        } : null);
    }, [userSettings, dropDownSettingsOpen]);

    useEffect(() => {
        if (tempSettings && userSettings) {
            if (tempSettings.invoiveSize === userSettings.invoiveSize) {
                setShowSave(false);
            } else {
                setShowSave(true);
            }
        }
    }, [tempSettings, userSettings]);

    const copySaleId = () => {
        try {
            const text = sale.id;
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The sale Id has been copied',
                    es: 'El Id de la venta ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Copy error',
                    es: 'Error al copiar',
                }
            });
        }
    }

    const handleChangeSettings = (e) => {
        setTempSittings({
            "invoiveSize": e.target.value,
        });
    }

    const setInvoiceTemp = (value) => {
        setTempSittings({
            "invoiveSize": value,
        });
    }

    async function updateInvoiceSize() {
        setLoadingSettings(true);

        try {
            let response = null;

            if (managerAccess) {
                response = {
                    ...tempSettings,
                    "linkSpires": "never",
                };
            } else {
                response = await firebase.useUpdateInvoiceSize({
                    businessId: accountData.id,
                    tempSettings: tempSettings,
                });
            }

            setUserSettings(response);

            saveKeyInIndexedDB("userSettings", response);

            setTempSittings(userSettings ? {
                "invoiveSize": response.invoiveSize,
            } : null);

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'Changes saved.',
                    es: 'Cambios guardados.',
                }
            });

            setDropDownSettingsOpen(false);
            setLoadingSettings(false);
        } catch (error) {
            console.error(error);
            setLoadingSettings(false);

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Error saving changes.',
                    es: 'Error al guardar cambios.',
                }
            });
        }
    }

    const onClose = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/', { replace: true });
        }
    }

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }

            if (accountData.useShifts) {
                if (shift !== null) {
                    if (!shift) {
                        navigate("/gestionar-turnos", { replace: true })
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [accountData, shift]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.sales)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    useEffect(() => {
        if (location.state) {
            setSale(location.state);
        } else {
            if (window.history.state && window.history.state.idx > 0) {
                navigate(-1);
            } else {
                navigate('/', { replace: true });
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (sale) {
            setReceiptData({
                ...sale.invoice,
                "items": sale.items,
                "subtotal": sale.subtotal,
                "desc": sale.desc,
                "itbis": sale.itbis,
                "total": sale.total,
                "payments": sale.payments
            })
        } else {
            setReceiptData(null);
        }
        // eslint-disable-next-line
    }, [sale]);

    const receiptRef = useRef();

    const getInvoiceSize = () => {
        if (userSettings) {
            if (userSettings.invoiveSize === "72mm") {
                return "(72 mm)"
            } else {
                if (userSettings.invoiveSize === "48mm") {
                    return "(48 mm)"
                } else {
                    return "(Normal)"
                }
            }
        } else {
            return ""
        }
    }

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "Successful sale " : "Venta exitosa"} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div className={`page-choose-account-item`}>
                            {accessTo.sales && sale ? <Fragment>
                                {desktopView ?
                                    <ToolTitle
                                        icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                        text={appLanguage.en ? "Successful sale " : "Venta exitosa"}
                                    />
                                    : null}

                                <div
                                    style={{
                                        height: desktopView ? "calc(100vh - 107px)" : "calc(100vh - 65px)",
                                        colorScheme: appTheme.dark ? "dark" : "light",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                    }}
                                    className="customer-details-page-main-container"
                                >

                                    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>

                                        <div className="sales-filter-botton-main unselectable" style={{ display: "flex", width: "100%", maxWidth: "622px", marginLeft: "30px" }}>

                                            <div ref={settingsRef} className="notification-filter-botton-container unselectable">
                                                <button
                                                    style={{
                                                        marginTop: "20px",
                                                        width: "70px",

                                                    }}
                                                    onClick={onClickSettingsButton}
                                                    className={`notification-list-config-btn ${appTheme.dark ? `${isTouchEnabled() ? "settings-notification-btn-dark-no-hover" : "settings-notification-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "settings-notification-btn-light-no-hover" : "settings-notification-btn-light"} border-box-light`}`}
                                                >
                                                    <img
                                                        style={{
                                                            width: "20px",
                                                            height: "20px",
                                                        }}
                                                        src={settingsIcon}
                                                        alt="Settings"
                                                    />
                                                </button>

                                                <span
                                                    className={`notification-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownSettingsOpen ? 'active' : 'inactive'}`}
                                                    style={{
                                                        marginLeft: "27px",
                                                    }}
                                                />

                                                <div
                                                    style={{
                                                        boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                        marginLeft: "-9px",
                                                        width: "240px",
                                                    }}
                                                    className={`dropdown-menu-notification ${appTheme.dark ? "dropdown-menu-notification-dark border-box-dark" : "dropdown-menu-notification-light border-box-light"} ${dropDownSettingsOpen ? 'active' : 'inactive'}`}
                                                >
                                                    {loadingSettings ?
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                minHeight: useMode === "development" ? "200px" : "170px",
                                                                paddingTop: "50px"
                                                            }}
                                                        >
                                                            <Spinner />
                                                        </div>
                                                        :
                                                        <Fragment>
                                                            {tempSettings ?
                                                                <div
                                                                    style={{
                                                                        minHeight: "220px",
                                                                    }}
                                                                >
                                                                    <div className="drop-men-not-option-con">
                                                                        <h3>{appLanguage.en ? "Receipt size to print" : "Tamaño del recibo a imprimir"}:</h3>

                                                                        {useMode === "development" ?
                                                                            <div
                                                                                className={`settings-bills-const-amount-to-pay-item ${tempSettings.invoiveSize === "letter" ? "settings-bills-const-amount-to-pay-item-hover" : null}`}
                                                                                onClick={() => setInvoiceTemp("letter")}
                                                                                style={{
                                                                                    padding: "5px 10px",
                                                                                    justifyContent: "flex-start"
                                                                                }}
                                                                            >
                                                                                <input
                                                                                    style={{ colorScheme: appTheme.dark ? "dark" : "light" }}
                                                                                    type="radio"
                                                                                    id="letter"
                                                                                    name="invoiveSize"
                                                                                    checked={tempSettings.invoiveSize === "letter" ? true : false}
                                                                                    value="letter"
                                                                                    onChange={handleChangeSettings}
                                                                                />
                                                                                <label htmlFor="letter">Normal</label>
                                                                            </div>
                                                                            : null}

                                                                        <div
                                                                            className={`settings-bills-const-amount-to-pay-item ${tempSettings.invoiveSize === "72mm" ? "settings-bills-const-amount-to-pay-item-hover" : null}`}
                                                                            onClick={() => setInvoiceTemp("72mm")}
                                                                            style={{
                                                                                padding: "5px 10px",
                                                                                justifyContent: "flex-start"
                                                                            }}
                                                                        >
                                                                            <input
                                                                                style={{ colorScheme: appTheme.dark ? "dark" : "light" }}
                                                                                type="radio"
                                                                                id="72mm"
                                                                                name="invoiveSize"
                                                                                checked={tempSettings.invoiveSize === "72mm" ? true : false}
                                                                                value="72mm"
                                                                                onChange={handleChangeSettings}
                                                                            />
                                                                            <label htmlFor="72mm">72 mm</label>
                                                                        </div>

                                                                        <div
                                                                            className={`settings-bills-const-amount-to-pay-item ${tempSettings.invoiveSize === "48mm" ? "settings-bills-const-amount-to-pay-item-hover" : null}`}
                                                                            onClick={() => setInvoiceTemp("48mm")}
                                                                            style={{
                                                                                padding: "5px 10px",
                                                                                justifyContent: "flex-start"
                                                                            }}
                                                                        >
                                                                            <input
                                                                                style={{ colorScheme: appTheme.dark ? "dark" : "light" }}
                                                                                type="radio"
                                                                                id="48mm"
                                                                                name="invoiveSize"
                                                                                checked={tempSettings.invoiveSize === "48mm" ? true : false}
                                                                                value="48mm"
                                                                                onChange={handleChangeSettings}
                                                                            />
                                                                            <label htmlFor="48mm">48 mm</label>
                                                                        </div>

                                                                    </div>

                                                                    <div
                                                                        className="drop-men-not-save-con"
                                                                        style={{
                                                                            marginTop: "35px"
                                                                        }}
                                                                    >
                                                                        <button
                                                                            onClick={updateInvoiceSize}
                                                                            disabled={!showSave}
                                                                            className="new-customer-bottons-button-save"
                                                                            style={!showSave ? {
                                                                                backgroundColor: "#3f526698",
                                                                                color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                                                cursor: "default"
                                                                            } : null}
                                                                        >
                                                                            <img src={saveIcon} alt="Save" />
                                                                            {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                : null}
                                                        </Fragment>
                                                    }
                                                </div>
                                            </div>

                                            {useMode === "development" ?
                                                <div ref={shareRef} className="notification-filter-botton-container unselectable">
                                                    <button
                                                        style={{
                                                            marginTop: "20px",
                                                            marginLeft: "15px",
                                                            minWidth: "150px"
                                                        }}
                                                        className={`notification-list-config-btn ${appTheme.dark ? `${isTouchEnabled() ? "settings-notification-btn-dark-no-hover" : "settings-notification-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "settings-notification-btn-light-no-hover" : "settings-notification-btn-light"} border-box-light`}`}
                                                        onClick={onClickShareButton}
                                                    >
                                                        <img
                                                            src={shareIcon}
                                                            style={{
                                                                width: "20px",
                                                                height: "20px",
                                                                marginRight: "8px"
                                                            }}
                                                            alt="Share"
                                                        />
                                                        <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Share" : "Compartir"}</p>
                                                    </button>

                                                    <span
                                                        className={`notification-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownShareOpen ? 'active' : 'inactive'}`}
                                                        style={{
                                                            marginLeft: "80px",
                                                        }}
                                                    />

                                                    <div
                                                        style={{
                                                            boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                            marginLeft: "-70px",
                                                            width: "220px",

                                                        }}
                                                        className={`dropdown-menu-notification ${appTheme.dark ? "dropdown-menu-notification-dark border-box-dark" : "dropdown-menu-notification-light border-box-light"} ${dropDownShareOpen ? 'active' : 'inactive'}`}
                                                    >
                                                        <div
                                                            style={{
                                                                minHeight: "220px",
                                                                paddingTop: "13px"
                                                            }}
                                                        >
                                                            <div className="drop-men-not-option-con" style={{ display: "flex", justifyContent: "center" }}>
                                                                <button
                                                                    className="invent-add-menu-btn"
                                                                    // disabled={loading ? true : false}
                                                                    onClick={null}
                                                                    style={{
                                                                        justifyContent: "flex-start"
                                                                    }}
                                                                >
                                                                    <img
                                                                        style={{
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            marginRight: "10px",
                                                                            marginLeft: "18px"
                                                                        }}
                                                                        src={downloadIconDark}
                                                                        alt="Download"
                                                                    />
                                                                    <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Download PDF" : "Descargar PDF"} </p>
                                                                </button>
                                                            </div>
                                                            <div className="drop-men-not-option-con" style={{ display: "flex", justifyContent: "center" }}>
                                                                <button
                                                                    className="invent-add-menu-btn"
                                                                    // disabled={loading ? true : false}
                                                                    onClick={null}
                                                                    style={{
                                                                        justifyContent: "flex-start"
                                                                    }}
                                                                >
                                                                    <img
                                                                        style={{
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            marginRight: "10px",
                                                                            marginLeft: "18px"
                                                                        }}
                                                                        src={copyIconDark}
                                                                        alt="Copy"
                                                                    />
                                                                    <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Copy link" : "Copiar enlace"} </p>
                                                                </button>
                                                            </div>
                                                            <div className="drop-men-not-option-con" style={{ display: "flex", justifyContent: "center" }}>
                                                                <button
                                                                    className="invent-add-menu-btn"
                                                                    // disabled={loading ? true : false}
                                                                    onClick={null}
                                                                    style={{
                                                                        justifyContent: "flex-start"
                                                                    }}
                                                                >
                                                                    <img
                                                                        style={{
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            marginRight: "10px",
                                                                            marginLeft: "18px"
                                                                        }}
                                                                        src={emailIconDark}
                                                                        alt="Email"
                                                                    />
                                                                    <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Email" : "Correo"} </p>
                                                                </button>
                                                            </div>
                                                            <div className="drop-men-not-option-con" style={{ display: "flex", justifyContent: "center" }}>
                                                                <button
                                                                    className="invent-add-menu-btn"
                                                                    // disabled={loading ? true : false}
                                                                    style={{
                                                                        marginBottom: "15px",
                                                                        justifyContent: "flex-start"
                                                                    }}
                                                                    onClick={null}
                                                                >
                                                                    <img
                                                                        style={{
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            marginRight: "10px",
                                                                            marginLeft: "18px"
                                                                        }}
                                                                        src={whatsAppIconDark}
                                                                        alt="WhatsApp"
                                                                    />
                                                                    <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "WhatsApp" : "WhatsApp"} </p>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            : null}
                                        </div>
                                    </div>

                                    <div style={{ display: "flex", width: "100%", justifyContent: "center", height: windowDimension.width > 550 ? "359px" : "538px" }}>
                                        <div
                                            style={{ display: "flex", width: "100%", maxWidth: "550px", marginTop: "25px" }}
                                            className={`settings-security ${appTheme.dark ? "dar-sec-bg dar-box-sha-keep-hover input-border-box-dark" : "lig-sec-bg lig-box-sha-keep-hover input-border-box-light"}`}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexWrap: windowDimension.width > 550 ? "nowrap" : "wrap"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        width: windowDimension.width > 550 ? "50%" : "100%",
                                                        borderBottom: "1px solid rgba(128, 128, 128, 0.315)"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: "80px", height: "80px" }}
                                                        src={sale.status === "completed" ? completedIcon : pendingIcon}
                                                        alt="Sales status"
                                                    />

                                                    {sale.status === "completed" ?
                                                        <p style={{ marginTop: "15px", fontWeight: "bold" }}>{appLanguage.en ? "Sale completed" : "Venta completa"}</p>
                                                        :
                                                        <p style={{ marginTop: "15px", fontWeight: "bold" }}>{appLanguage.en ? "Pending sale" : "Venta pendiente"}</p>
                                                    }

                                                    <span
                                                        onClick={copySaleId}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}
                                                        className={`sale-copy-id-successful ${isTouchEnabled() ? "sale-copy-id-successful-no-hover" : "sale-copy-id-successful-hover"}`}
                                                    >

                                                        <p style={{ marginRight: "10px" }}>{sale.id}</p>

                                                        <img
                                                            src={appTheme.dark ? copyIconDark : copyIconLight}
                                                            style={{ width: "16px", height: "16px" }}
                                                            alt="Copy"
                                                        />
                                                    </span>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        width: windowDimension.width > 550 ? "50%" : "100%",
                                                        borderBottom: "1px solid rgba(128, 128, 128, 0.315)",
                                                        paddingBottom: "15px"
                                                    }}
                                                >

                                                    <p style={{ marginBottom: "10px", marginTop: "20px" }}><b>{appLanguage.en ? "Summary" : "Resumen"}</b></p>

                                                    <table cellPadding="10" cellSpacing="8" width="215px">
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400" }}>Subtotal: </td>
                                                                <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", minWidth: "100px" }}>$ {formatRationalNumber(sale.subtotal)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", whiteSpace: "nowrap", }}>Desc{sale.discount ? (sale.discount.type === "percentage" ? ` ${sale.discount.value}%` : "") : ""}: </td>
                                                                <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", minWidth: "100px" }}>$ {formatRationalNumber(sale.desc)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400" }}>Itbis: </td>
                                                                <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", minWidth: "100px" }}>$ {formatRationalNumber(sale.itbis)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400" }}>Total: </td>
                                                                <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", minWidth: "100px" }}>$ {formatRationalNumber(sale.total)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    width: "100%",
                                                    justifyContent: "space-around",
                                                    marginTop: "30px",
                                                    marginBottom: "20px"
                                                }}
                                            >

                                                {windowDimension.width > 385 ?
                                                    <button
                                                        onClick={onClose}
                                                        className="new-customer-bottons-button-save"
                                                    >
                                                        <img src={crossIcon} alt="Close" />
                                                        {appLanguage.en ? <p>Close</p> : <p>Cerrar</p>}
                                                    </button>
                                                    : null}

                                                {receiptData && (
                                                    <ReactToPrint
                                                        trigger={() =>

                                                            <button
                                                                onClick={null}
                                                                className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                            >
                                                                <img src={printIconDark} alt="Print" />

                                                                {appLanguage.en ?
                                                                    <p style={{ whiteSpace: "nowrap" }}>Print {getInvoiceSize()} </p>
                                                                    :
                                                                    <p style={{ whiteSpace: "nowrap" }}>Imprimir {getInvoiceSize()} </p>
                                                                }
                                                            </button>
                                                        }
                                                        content={() => receiptRef.current}
                                                    />
                                                )}

                                                <SaleReceipt receiptData={receiptData} receiptRef={receiptRef} />

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </Fragment> : null}
                        </div>
                        : null}
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default SuccessfulSalePage;