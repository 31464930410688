import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertContext } from "../../../components/alert/alertContext";
import { AppContext } from "../../../context/AppContext";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";

import noPicture from "../../../img/no-picture.png";
import noReady from "../../../img/nothing.png";
import error404 from "../../../img/404-error.png";

import Spinner from "../../../components/spinner/spinner";
import Spinner3 from "../../../components/spinner3/spinner3";

import { uid } from "uid";

import "./productDetails.scss";

import openIcon from "../../../icons/arrow-small-right-white.png";

import addImg from "../../../icons/add-to-cart-dark.png";

import removeImgLight from "../../../icons/remove-from-cart-light.png";
import removeImgDark from "../../../icons/remove-from-cart-dark.png";

import { AuthContext } from "../../../firebase/context";

import firebase from "../../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";

import uploadIconDark from "../../../icons/upload-dark.png";
import uploadIconLight from "../../../icons/upload-light.png";
import { SideMenuContext } from "../../../context/SideMenuContext";

const ProductDetails = ({ 
    product,
    setProduct,
    pictureProductViewerActive,
    onClickPicture,
    pictureData,
    setPictureData,
    loadingError,
    noImgFile,
    setWidth,
    addToCart,
    removeFromCart,
    loadingPicture,
    setUploadingPicture,
    uploadingPicture, 
    getPictureFileAfterUploading
}) => {

    const { appLanguage, appTheme, formatRationalNumber, getCategory, getRate, isTouchEnabled } = useContext(AppContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { accountData, accessTo, isItemInShoppingCart, updateProductInShoppingCart } = useContext(AuthContext);

    const { windowDimension } = useContext(SideMenuContext);

    const [ loading, setLoading ] = useState(true);

    const blink = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }

    useEffect(() => {
        blink();
    }, [])

    const { setAlertData, setAlertActive } = useContext(AlertContext);

    const navigate = useNavigate();

    const copyCode = () => {
        try {
            const text = product.barcode;
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The barcode price has been copied',
                    es: 'El código de barras ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Copy error',
                    es: 'Error al copiar',
                }
            });
        }
    }

    const copyName = () => {
        const text = product.name;

        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The name has been copied',
                    es: 'El nombre ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Copy error',
                    es: 'Error al copiar',
                }
            });
        }
    }

    const copyPriceWithoutTax = () => {
        const text = formatRationalNumber(product.priceWithoutTax);

        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The price without ITBIS has been copied',
                    es: 'El precio sin ITBIS ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Copy error',
                    es: 'Error al copiar',
                }
            });
        }
    }

    const copyFinalPrice = () => {
        const text = formatRationalNumber(product.finalPrice);

        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The final price has been copied',
                    es: 'El precio final ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Copy error',
                    es: 'Error al copiar',
                }
            });
        }
    }

    const copyQuantity = () => {
        const text = product.quantity;

        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The quantity has been copied',
                    es: 'La cantidad ha sido copiada',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Copy error',
                    es: 'Error al copiar',
                }
            });
        }
    }

    const copyLocation = () => {
        const text = product.location;

        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The location has been copied',
                    es: 'La ubicación ha sido copiada',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Copy error',
                    es: 'Error al copiar',
                }
            });
        }
    }

    const copyDescription = () => {
        const text = product.description;

        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The description has been copied',
                    es: 'La descripción ha sido copiada',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Copy error',
                    es: 'Error al copiar',
                }
            });
        }
    }

    const copyCategory = () => {
        const text = getCategory(product.category);

        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The category has been copied',
                    es: 'La categoría ha sido copiada',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Copy error',
                    es: 'Error al copiar',
                }
            });
        }
    }

    const copyItbis = () => {
        const text = formatRationalNumber(product.itbisAmount);

        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The ITBIS has been copied',
                    es: 'El ITBIS ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Copy error',
                    es: 'Error al copiar',
                }
            });
        }
    }

    const onOpenInInventory = () => {
        navigate('/detalles-de-producto', { state: product });
    }

    async function uploadImage(file) {
        setUploadingPicture(true);

        try {
            const fileName = uid(16);
            const newPath = `accounts/${accountData.id}/products/${product.id}/${fileName}`;

            const imageRef = ref(firebase.storage, newPath);

            await uploadBytes(imageRef, file);

            const productRef = doc(firebase.db, `accounts/${accountData.id}/products`, product.id);

            // Atomically add a new region to the "regions" array field.
            await updateDoc(productRef, {
                picturesLocation: [newPath],
            });

            setProduct({
                ...product,
                picturesLocation: [newPath],
            });

            updateProductInShoppingCart({
                ...product,
                picturesLocation: [newPath],
            });

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'Image uploaded',
                    es: 'Imagen subida',
                }
            });

            setPictureData(null);

            // Wait for the image to be ready for download
            setTimeout(() => {
                getPictureFileAfterUploading(newPath);
            }, 1000);

          
        } catch (error) {
            console.error(error);
            setUploadingPicture(false);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Connection issues',
                    es: 'Problemas de conexión',
                }
            });
        }
    }

    const onChangeImage = (file) => {
        if (file.type.startsWith('image/')) {
            uploadImage(file);
        } else {
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error trying to open image',
                    es: 'Error al intentar abrir la imagen',
                },
                code: "invalid file",
                description: {
                    en: 'Please select a valid image.',
                    es: 'Por favor, seleccione una imagen válida.'
                }
            });
            setAlertActive(true);
        }
    }

    let observedRef = useRef(null);

    const [horizontal, setHorizontal] = useState(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return false;
            } else {
                try {
                    setWidth(observedRef.current.offsetWidth + 12)
                    if ((observedRef.current.offsetWidth - 11) > 715) {
                        return true;
                    } else {
                        return false;
                    }
                } catch {
                    return false;
                }
            }
        } else {
            return false;
        }
    });

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }
            const resizeObserver = new ResizeObserver(() => {
                try {
                    setWidth(observedRef.current.offsetWidth + 12)
                    if ((observedRef.current.offsetWidth - 11) > 715) {
                        setHorizontal(true);
                    } else {
                        setHorizontal(false);
                    }
                } catch { }
            });

            resizeObserver.observe(observedRef.current);
            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension]);

    const canBeAddedToShoppingCart = () => {
        if (isItemInShoppingCart(product.id)) {
            return true;
        } else {
            if (product && product.quantity && product.quantity > 0 ) {
                return true;
            } else {
                return false;
            }
        }
    }

    return (
        <div
            ref={observedRef}
            className={`products-detaines-info-cont`}
        >
            <br />
            {loading ? <div style={{ marginTop: "90px" }} className="more-details-spinner-container"><Spinner /></div> : (product ? <Fragment>
                <div
                    style={{
                        maxWidth: horizontal ? "700px" : "450px",
                    }}
                    className={`products-details-page-main-container ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
                >
                    <div
                        style={horizontal ? { maxWidth: "300px" } : {}}
                        className="products-more-details-section-first"
                    >
                        {accessTo.productsSup ?
                            <div className="products-details-edict-options-btn" >
                                <label
                                    htmlFor="inputTag"
                                    style={{ color: appTheme.dark ? "white" : "black" }}
                                    className={`${isTouchEnabled() ? "product-picture-upload-butt-no-hover" : "product-picture-upload-butt"} unselectable`}
                                >
                                    <p>{appLanguage.en ? "Upload image" : "Subir imagen"}</p>
                                    <img src={appTheme.dark ? uploadIconDark : uploadIconLight} alt="Receipt" />
                                    <input
                                        id="inputTag"
                                        type="file"
                                        accept="image/*"
                                        value=""
                                        onChange={(event) => onChangeImage(event.target.files[0])}
                                    />
                                </label>
                            </div>
                        : null}

                        {(loadingPicture || uploadingPicture) ? 
                            <div className="products-individual-product-yes-img-big-cont unselectable">
                                <div 
                                    style={{
                                        backgroundColor: "rgba(128, 128, 128, 0.13)", 
                                        borderRadius: "5px",
                                        display: "flex",
                                        alignItems: "center"
                                    }} 
                                    className="pro-ind-prod-yes-img-big-con-div"
                                >
                                    <Spinner3 />
                                </div>
                            </div>
                        :
                            <Fragment>
                                {noImgFile && (!loadingError) ?
                                    <div className="products-individual-product-yes-img-big-cont unselectable">
                                        <div onClick={onClickPicture} className="pro-ind-prod-yes-img-big-con-div">
                                            <img className={`products-individual-product-yes-img-big`} src={pictureData ? (pictureProductViewerActive ? noReady : pictureData.pictureFile) : noReady} alt="Product" />
                                        </div>
                                    </div>
                                    :
                                    <div className="products-individual-product-no-img-big-cont unselectable">
                                        <div className="pro-ind-prod-no-img-big-con-div">
                                            <img className="products-individual-product-no-img-big" src={loadingError ? error404 : noPicture} alt="Product" />
                                        </div>
                                    </div>
                                }
                            </Fragment>
                        }
                        
                        {accessTo.sales && canBeAddedToShoppingCart() ? 
                            isItemInShoppingCart(product.id) ? 
                                <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "20px", marginBottom: "15px" }}>
                                    <button
                                        onClick={removeFromCart}
                                        className={isTouchEnabled() ? "remove-from-card-btn-product-no-hover" : "remove-from-card-btn-product"}
                                        style={{
                                            padding: "8px 20px"
                                        }}
                                    >
                                    <img 
                                        src={appTheme.dark ? removeImgDark : removeImgLight} 
                                        alt="Remove from cart" 
                                        style={{
                                            width: "25px", 
                                            height: "25px",
                                        }} />
                                        <p style={{ marginTop: "-3px" }}>{appLanguage.en ? "Remove from cart" : "Remover del carrito"}</p>
                                    </button>
                                </div>
                            :
                                <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "20px", marginBottom: "15px" }}>
                                    <button
                                        onClick={addToCart}
                                        className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                        style={{
                                            padding: "8px 20px"
                                        }}
                                    >
                                    <img 
                                        src={addImg} 
                                        alt="Add to cart" 
                                        style={{
                                            width: "25px", 
                                            height: "25px",
                                        }} />
                                        <p style={{ marginTop: "-3px" }}>{appLanguage.en ? "Add to cart" : "Añadir al carrito"}</p>
                                    </button>
                                </div>
                        : null}
                    </div>

                    <div
                        style={horizontal ? { maxWidth: "350px" } : {}}
                        className="products-more-details-section-second"
                    >
                        <p className="customer-detaines-info-p" style={{ marginTop: "10px" }}>{product.name.trim() !== "" ? <span onClick={copyName} style={{ fontSize: "20px", fontWeight: "bold" }} className="customer-detaines-info-p-span-cont">{product.name}</span> : ""}</p>
                        <p className="customer-detaines-info-p" style={{ marginBottom: "25px" }}>{typeof product.priceWithoutTax === 'number' ? <span onClick={copyFinalPrice} style={{ color: appTheme.dark ? "#a7d0ff" : "#2089ff", fontSize: "19px", fontWeight: "bold" }} className="customer-detaines-info-p-span-cont">RD$ {formatRationalNumber(product.finalPrice)} / {getRate(product.rate)}</span> : "--"}</p>

                        <p className="customer-detaines-info-p"><span className="customer-detaines-info-p-span-title">{appLanguage.en ? "Description: " : "Descripción: "}  </span>{product.description ? <span onClick={copyDescription} className="customer-detaines-info-p-span-cont">{product.description}</span> : "--"}</p>
                        <p className="customer-detaines-info-p"><span className="customer-detaines-info-p-span-title">{appLanguage.en ? "Category: " : "Categoria: "}</span>{product.category ? <span onClick={copyCategory} className="customer-detaines-info-p-span-cont">{getCategory(product.category)}</span> : "--"}</p>

                        <p className="customer-detaines-info-p">
                            <span className="customer-detaines-info-p-span-title">{appLanguage.en ? "Quantity Available: " : "Cantidad disponible: "}</span>
                            {typeof product.quantity === 'number' ?
                                product.quantity > 0 ?
                                    <span onClick={copyQuantity} className="customer-detaines-info-p-span-cont">{product.quantity.toLocaleString()}</span>
                                    :
                                    <span className="customer-detaines-info-out-of-stock-span-cont">{appLanguage.en ? "Unavailable" : "Agotado"}</span>
                                : "--"}
                        </p>

                        <p className="customer-detaines-info-p"><span className="customer-detaines-info-p-span-title">{appLanguage.en ? "Location: " : "Ubicación: "}  </span>{product.location ? <span onClick={copyLocation} className="customer-detaines-info-p-span-cont">{product.location}</span> : "--"}</p>
                        <p className="customer-detaines-info-p"><span className="customer-detaines-info-p-span-title">{appLanguage.en ? "Barcode: " : "Código de barra: "}  </span>{product.barcode ? <span onClick={copyCode} className="customer-detaines-info-p-span-cont">{product.barcode}</span> : "--"}</p>

                        <p className="customer-detaines-info-p"><span className="customer-detaines-info-p-span-title">{appLanguage.en ? "Price without ITBIS: " : "Precio sin ITBIS: "}  </span>{product.finalPrice ? <span onClick={copyPriceWithoutTax} className="customer-detaines-info-p-span-cont">RD$ {formatRationalNumber(product.priceWithoutTax)}</span> : "--"}</p>
                        <p className="customer-detaines-info-p"><span className="customer-detaines-info-p-span-title">{`ITBIS (${product.itbisPercentage}):`}  </span>{product.itbisAmount ? <span onClick={copyItbis} className="customer-detaines-info-p-span-cont">RD$ {formatRationalNumber(product.itbisAmount)}</span> : "--"}</p>
                    </div>

                </div>
                <div
                    className="new-customer-bottons-container2 unselectable"
                    style={{
                        maxWidth: horizontal ? "700px" : "550px",
                        paddingBottom: "30px",
                        justifyContent: "flex-end",
                    }}
                >
                    {accessTo.productsSup ?
                        <button
                            onClick={e => onOpenInInventory(e)}
                            className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                            style={{marginRight: "20px"}}
                        >
                            {appLanguage.en ? <p>Open in inventory</p> : <p>Abrir en inventario</p>}
                            <img style={{ margin: "0 0 0 8px", width: "20px", height: "20px"}} src={openIcon} alt="Open" />
                        </button> 
                    : null}
                </div>
            </Fragment> : null)}
        </div>
    );
}

export default ProductDetails;