import "./saleView.page.scss";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import ReactToPrint from 'react-to-print';
// import JsBarcode from 'jsbarcode';

import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import { SideMenuContext } from "../../../context/SideMenuContext";

import firebase from "../../../firebase";
import { onSnapshot, doc } from "firebase/firestore";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import deleteIcon from "../../../icons/closed-dark.png";
import printIconDark from "../../../icons/printer-dark.png";
import dollarSighDarkIcon from "../../../icons/dollar-sign-dark.png";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import saveIcon from "../../../icons/check-dark.png";

import adjustmentIconDark from '../../../icons/adjustment-dark.png';
import adjustmentIconLight from '../../../icons/adjustment-light.png';

import xCancelDark from "../../../icons/cancel-dark.png";
import xCancelLight from "../../../icons/cancel-light.png";

import sendIconDark from "../../../icons/send-dark.png";
import sendIconLight from "../../../icons/send-light.png";

import downloadIconDark from "../../../icons/download-dark.png";
import copyIconDark from "../../../icons/copy.png";
import emailIconDark from "../../../icons/email-dark.png";
import whatsAppIconDark from "../../../icons/whatsapp-icon-dark.png";

import { uid } from "uid";
import Spinner from "../../../components/spinner/spinner";
import ConnectionError from "../../../components/connection-error/connectionError";
import CollectPaymentFromSale from "../../../appTools/appToolsComponents/collectPayment/collectPaymentFromSale";
import { CollectPaymentContext } from "../../../appTools/appToolsComponents/collectPayment/collectPaymentContext";
import SalesSummaryTable from "../../../appTools/appToolsComponents/salesSummaryTable/salesSummaryTable";
import { AlertContext } from "../../../components/alert/alertContext";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";
import SaleReceipt from "../../../receipts/saleReceipt";
import ShareViaExternalApp from "../../../appTools/appToolsComponents/shareViaExternalApp/shareViaExternalApp";
import { ShareViaExternalAppContext } from "../../../appTools/appToolsComponents/shareViaExternalApp/shareViaExternalAppContext";

const SaleViwePage = () => {

    const { appTheme, appLanguage, isTouchEnabled, formatRationalNumber, setDropDownCartOpen, setDropDownProfileMenuOpen, useMode } = useContext(AppContext);
    const { setAlertData, setAlertActive, setOnAgree, getErrorDescription } = useContext(AlertContext);
    const { accountData, accessTo, userSettings, setUserSettings, saveKeyInIndexedDB, managerAccess } = useContext(AuthContext);

    const { setCashAmount, setCashToCharge, setCollectPaymentActive } = useContext(CollectPaymentContext);
    const { setContactType, setShareViaExternalAppActive } = useContext(ShareViaExternalAppContext);

    const { windowDimension, desktopView, isSideMenuMinimized } = useContext(SideMenuContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);

    let settingsRef = useRef();
    let shareRef = useRef();

    const location = useLocation();

    const [saleId, setSaleId] = useState(null);
    const [receiptData, setReceiptData] = useState(null);

    const [loadingSettings, setLoadingSettings] = useState(false);

    const [showSave, setShowSave] = useState(false);

    const [settingsIcon, setSettingsIcon] = useState(appTheme.dark ? adjustmentIconDark : adjustmentIconLight);

    const [shareIcon, setShareIcon] = useState(appTheme.dark ? sendIconDark : sendIconLight);

    const [dropDownSettingsOpen, setDropDownSettingsOpen] = useState(false);

    const [dropDownShareOpen, setDropDownShareOpen] = useState(false);

    useEffect(() => {
        if (dropDownSettingsOpen) {
            setSettingsIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setSettingsIcon(appTheme.dark ? adjustmentIconDark : adjustmentIconLight);
        }
    }, [dropDownSettingsOpen, appTheme]);

    useEffect(() => {
        if (dropDownShareOpen) {
            setShareIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setShareIcon(appTheme.dark ? sendIconDark : sendIconLight);
        }
    }, [dropDownShareOpen, appTheme]);

    const onClickSettingsButton = () => {
        dropDownSettingsOpen ? setDropDownSettingsOpen(false) : setDropDownSettingsOpen(true);
    }

    const onClickShareButton = () => {
        dropDownShareOpen ? setDropDownShareOpen(false) : setDropDownShareOpen(true);
    }

    useEffect(() => {
        if (dropDownSettingsOpen && settingsRef) {
            if (settingsRef.current) {
                let handler = (e) => {
                    if (!settingsRef.current.contains(e.target)) {
                        setDropDownSettingsOpen(false);
                    };
                };
                document.addEventListener("mousedown", handler);
                return () => {
                    document.removeEventListener("mousedown", handler);
                }
            }
        }
    });

    useEffect(() => {
        if (dropDownShareOpen && shareRef) {
            if (shareRef.current) {
                let handler = (e) => {
                    if (!shareRef.current.contains(e.target)) {
                        setDropDownShareOpen(false);
                    };
                };
                document.addEventListener("mousedown", handler);
                return () => {
                    document.removeEventListener("mousedown", handler);
                }
            }
        }
    });

    const [tempSettings, setTempSittings] = useState(userSettings ? {
        "invoiveSize": userSettings.invoiveSize,
    } : null);

    useEffect(() => {
        setTempSittings(userSettings ? {
            "invoiveSize": userSettings.invoiveSize,
        } : null);
    }, [userSettings, dropDownSettingsOpen]);

    useEffect(() => {
        if (tempSettings && userSettings) {
            if (tempSettings.invoiveSize === userSettings.invoiveSize) {
                setShowSave(false);
            } else {
                setShowSave(true);
            }
        }
    }, [tempSettings, userSettings]);

    const [request, setRequest] = useState("0000");

    const [showConnectionError, setShowConnectionError] = useState(false);

    const [loading, setLoading] = useState(true);

    const [sale, setSale] = useState(null);

    const [customerId, setCustomerId] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [loadingCustomer, setLoadingCustomer] = useState(true);

    useEffect(() => {
        if (customerId) {
            const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/customers`, customerId), (doc) => {
                const consumerUpdated = {
                    ...doc.data(),
                    "id": doc.id,
                };

                delete consumerUpdated.searchKeys;

                if (doc.data()) {
                    setCustomer(consumerUpdated);
                    setLoadingCustomer(false);
                }
            }, err => {
                console.error(err);
                setLoadingCustomer(false);
            });
            return () => unsub();
        }
        // eslint-disable-next-line
    }, [customerId]);

    const handleChangeSettings = (e) => {
        setTempSittings({
            "invoiveSize": e.target.value,
        });
    }

    const setInvoiceTemp = (value) => {
        setTempSittings({
            "invoiveSize": value,
        });
    }

    async function updateInvoiceSize() {
        setLoadingSettings(true);

        try {
            let response = null;

            if (managerAccess) {
                response = {
                    ...tempSettings,
                    "linkSpires": "never",
                };
            } else {
                response = await firebase.useUpdateInvoiceSize({
                    businessId: accountData.id,
                    tempSettings: tempSettings,
                });
            }

            setUserSettings(response);

            saveKeyInIndexedDB("userSettings", response);

            setTempSittings(userSettings ? {
                "invoiveSize": response.invoiveSize,
            } : null);

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'Changes saved.',
                    es: 'Cambios guardados.',
                }
            });

            setDropDownSettingsOpen(false);
            setLoadingSettings(false);
        } catch (error) {
            console.error(error);
            setLoadingSettings(false);

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Error saving changes.',
                    es: 'Error al guardar cambios.',
                }
            });
        }
    }

    useEffect(() => {
        if (sale) {
            setCustomerId(sale.customerId);
            setReceiptData({
                ...sale.invoice,
                "items": sale.items,
                "subtotal": sale.subtotal,
                "desc": sale.desc,
                "itbis": sale.itbis,
                "total": sale.total,
                "payments": sale.payments
            })
        } else {
            setReceiptData(null);
        }
        // eslint-disable-next-line
    }, [sale]);

    const receiptRef = useRef();

    // Now I have to listeng to the product doc
    useEffect(() => {
        if (saleId && (request !== "0000")) {
            setShowConnectionError(false);
            const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/sales`, saleId.id), (doc) => {
                const saleData = {
                    ...doc.data(),
                    "id": doc.id,
                };

                delete saleData.searchKeys;

                if (doc.data()) {
                    setSale(saleData);
                    setShowConnectionError(false);
                } else {
                    setShowConnectionError(true);
                }

                // Si el producto no exite entonce no existe
                setLoading(false);
            }, err => {
                console.error(err);
                setShowConnectionError(true);
                setLoading(false);
            });
            return () => unsub();
        }
        // eslint-disable-next-line
    }, [request]);

    const onTryAgain = () => {
        setRequest(uid());
    }

    const navigate = useNavigate();

    let headerObservedRef = useRef(null);
    let pageObserverRef = useRef(null);

    const [headerHeight, setHeaderHeight] = useState(107);

    const [pageWidth, setPageWidth] = useState(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return windowDimension.width;
            } else {
                try {
                    const width = pageObserverRef.current.offsetWidth
                    return width;
                } catch {
                    return windowDimension.width;
                }
            }
        } else {
            return windowDimension.width;
        }
    });

    useEffect(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return;
            }
            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (pageObserverRef.current.offsetWidth !== pageWidth) {
                        setPageWidth(pageObserverRef.current.offsetWidth);
                    }
                } catch { }
            });
            resizeObserver.observe(pageObserverRef.current);
            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [pageObserverRef.current, windowDimension, isSideMenuMinimized]);

    useEffect(() => {
        if (headerObservedRef) {
            if (!headerObservedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (headerObservedRef.current.offsetHeight !== headerHeight) {
                        setHeaderHeight(headerObservedRef.current.offsetHeight);
                    }
                } catch { }
            });

            resizeObserver.observe(headerObservedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [headerObservedRef.current, windowDimension, isSideMenuMinimized]);

    useEffect(() => {
        if (location.state) {
            setSaleId({
                "id": location.state.id
            });
            setRequest(uid());
        }
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.sales)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    useEffect(() => {
        navigate(null, { replace: true, state: saleId });
        // eslint-disable-next-line
    }, [saleId]);

    const onCancelSale = async () => {
        setLoading(true);
        setAlertActive(false);

        try {
            const res = await firebase.useCancelSale({
                businessId: accountData.id,
                saleId: sale.id,
            });

            setSale(res)
            setLoading(false);

            setAlertData({
                type: 'success',
                title: {
                    en: 'Sale canceled',
                    es: 'Venta cancelada'
                },
                code: '',
                description: {
                    en: `Sale ${sale.id} has been successfully cancelled. You can print the receipt for more details.`,
                    es: `La venta ${sale.id} ha sido cancelada de manera exitosa. Puedes imprimir el recibo para obtener más detalles.`,
                }
            });
            setAlertActive(true);

        } catch (error) {
            console.error(error);
            const { code } = JSON.parse(JSON.stringify(error));
            setAlertData({
                type: 'error',
                title: {
                    en: `Error trying to cancel this sale.`,
                    es: `Error al intentar cancelar esta venta.`
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
            setLoading(false);
        }
    }

    const onDelete = () => {
        setAlertData({
            type: 'question',
            title: {
                en: `Delete sale?`,
                es: `¿Eliminar venta?`
            },
            code: '',
            description: {
                en: `Are you sure you want to cancel the sale ${sale.id}?`,
                es: `¿Estás seguro de que deseas cancelar la venta ${sale.id}?`
            }
        });
        setOnAgree(() => onCancelSale);
        setAlertActive(true);
    }

    const isCustomerSelected = () => {
        if (sale) {
            if (sale.customerId) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const onOpenCustomer = () => {
        if (isCustomerSelected()) {
            navigate("/ver-cliente", { state: customer })
        }
    }

    const onClickOpenProduct = (product) => {
        navigate("/ver-producto", { state: product.product })
    }

    const onCollectPayment = () => {
        setCollectPaymentActive(true);
        if (sale && (!sale.splitPayment)) {
            setCashAmount(formatRationalNumber(Math.round(sale.total)));
            setCashToCharge(formatRationalNumber(Math.round(sale.total)));
        }
    }

    const onScroll = () => {
        setDropDownCartOpen(false);
        setDropDownProfileMenuOpen(false);
    }

    const canDeleteSale = () => {
        if (sale.status !== "canceled") {
            if (accessTo.salesSup) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const canPrintInvoice = () => {
        if (sale.invoice) {
            return true;
        } else {
            return false;
        }
    }

    const getInvoiceSize = () => {
        if (userSettings) {
            if (userSettings.invoiveSize === "72mm") {
                return "(72 mm)"
            } else {
                if (userSettings.invoiveSize === "48mm") {
                    return "(48 mm)"
                } else {
                    return "(Normal)"
                }
            }
        } else {
            return ""
        }
    }

    const onClickShareOption = (option) => {
        setContactType(option) 
        setShareViaExternalAppActive(true);
        setDropDownShareOpen(false);
    }

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "View sale details" : "Ver detalles de venta"}</title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`}>
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div ref={pageObserverRef} className={`page-choose-account-item`}>
                            {accessTo.sales ? <Fragment>

                                <CollectPaymentFromSale
                                    pickerWidth={pageWidth}
                                    sale={sale}
                                    setSale={setSale}
                                />

                                <ShareViaExternalApp
                                    pickerWidth={pageWidth}
                                    sale={sale}
                                    customer={customer}
                                />

                                {desktopView ?
                                    <ToolTitle
                                        icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                        text={appLanguage.en ? "View sale details" : "Ver detalles de venta"}
                                    />
                                    : null}

                                {loading ? <div style={{ marginTop: "60px" }} className="more-details-spinner-container"><Spinner /></div> :

                                    (showConnectionError ? <ConnectionError onTryAgain={onTryAgain} /> :
                                        (sale ?
                                            <Fragment>

                                                <div style={{ marginTop: "25px", display: "flex", width: "100%", justifyContent: "center" }}>
                                                    {/** Main container for seccion */}
                                                    <div style={{ width: "100%", maxWidth: "745px" }}>


                                                        <div ref={headerObservedRef} style={{ display: "flex", marginBottom: "5px", marginLeft: "5px", flexWrap: "wrap" }}>
                                                            <div ref={settingsRef} className="notification-filter-botton-container unselectable">
                                                                <button
                                                                    style={{
                                                                        marginLeft: "15px",
                                                                        width: "70px",
                                                                        marginBottom: "15px",
                                                                    }}
                                                                    onClick={onClickSettingsButton}
                                                                    className={`notification-list-config-btn ${appTheme.dark ? `${isTouchEnabled() ? "settings-notification-btn-dark-no-hover" : "settings-notification-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "settings-notification-btn-light-no-hover" : "settings-notification-btn-light"} border-box-light`}`}
                                                                >
                                                                    <img
                                                                        style={{
                                                                            width: "20px",
                                                                            height: "20px",
                                                                        }}
                                                                        src={settingsIcon}
                                                                        alt="Settings"
                                                                    />
                                                                </button>

                                                                <span
                                                                    className={`notification-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownSettingsOpen ? 'active' : 'inactive'}`}
                                                                    style={{
                                                                        marginLeft: "42px",
                                                                        marginTop: "-8px",
                                                                    }}
                                                                />

                                                                <div
                                                                    style={{
                                                                        boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                                        marginLeft: "15px",
                                                                        marginTop: "-4px",
                                                                        width: "240px",
                                                                    }}
                                                                    className={`dropdown-menu-notification ${appTheme.dark ? "dropdown-menu-notification-dark border-box-dark" : "dropdown-menu-notification-light border-box-light"} ${dropDownSettingsOpen ? 'active' : 'inactive'}`}
                                                                >
                                                                    {loadingSettings ?
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                minHeight: useMode === "development" ? "200px" : "170px",
                                                                                paddingTop: "50px"
                                                                            }}
                                                                        >
                                                                            <Spinner />
                                                                        </div>
                                                                        :
                                                                        <Fragment>
                                                                            {tempSettings ?
                                                                                <div
                                                                                    style={{
                                                                                        minHeight: "220px",
                                                                                    }}
                                                                                >
                                                                                    <div className="drop-men-not-option-con">
                                                                                        <h3>{appLanguage.en ? "Receipt size to print" : "Tamaño del recibo a imprimir"}:</h3>

                                                                                        {useMode === "development" ?
                                                                                            <div
                                                                                                className={`settings-bills-const-amount-to-pay-item ${tempSettings.invoiveSize === "letter" ? "settings-bills-const-amount-to-pay-item-hover" : null}`}
                                                                                                onClick={() => setInvoiceTemp("letter")}
                                                                                                style={{
                                                                                                    padding: "5px 10px",
                                                                                                    justifyContent: "flex-start"
                                                                                                }}
                                                                                            >
                                                                                                <input
                                                                                                    style={{ colorScheme: appTheme.dark ? "dark" : "light" }}
                                                                                                    type="radio"
                                                                                                    id="letter"
                                                                                                    name="invoiveSize"
                                                                                                    checked={tempSettings.invoiveSize === "letter" ? true : false}
                                                                                                    value="letter"
                                                                                                    onChange={handleChangeSettings}
                                                                                                />
                                                                                                <label htmlFor="letter">Normal</label>
                                                                                            </div>
                                                                                            : null}

                                                                                        <div
                                                                                            className={`settings-bills-const-amount-to-pay-item ${tempSettings.invoiveSize === "72mm" ? "settings-bills-const-amount-to-pay-item-hover" : null}`}
                                                                                            onClick={() => setInvoiceTemp("72mm")}
                                                                                            style={{
                                                                                                padding: "5px 10px",
                                                                                                justifyContent: "flex-start"
                                                                                            }}
                                                                                        >
                                                                                            <input
                                                                                                style={{ colorScheme: appTheme.dark ? "dark" : "light" }}
                                                                                                type="radio"
                                                                                                id="72mm"
                                                                                                name="invoiveSize"
                                                                                                checked={tempSettings.invoiveSize === "72mm" ? true : false}
                                                                                                value="72mm"
                                                                                                onChange={handleChangeSettings}
                                                                                            />
                                                                                            <label htmlFor="72mm">72 mm</label>
                                                                                        </div>

                                                                                        <div
                                                                                            className={`settings-bills-const-amount-to-pay-item ${tempSettings.invoiveSize === "48mm" ? "settings-bills-const-amount-to-pay-item-hover" : null}`}
                                                                                            onClick={() => setInvoiceTemp("48mm")}
                                                                                            style={{
                                                                                                padding: "5px 10px",
                                                                                                justifyContent: "flex-start"
                                                                                            }}
                                                                                        >
                                                                                            <input
                                                                                                style={{ colorScheme: appTheme.dark ? "dark" : "light" }}
                                                                                                type="radio"
                                                                                                id="48mm"
                                                                                                name="invoiveSize"
                                                                                                checked={tempSettings.invoiveSize === "48mm" ? true : false}
                                                                                                value="48mm"
                                                                                                onChange={handleChangeSettings}
                                                                                            />
                                                                                            <label htmlFor="48mm">48 mm</label>
                                                                                        </div>

                                                                                    </div>

                                                                                    <div
                                                                                        className="drop-men-not-save-con"
                                                                                        style={{
                                                                                            marginTop: "35px"
                                                                                        }}
                                                                                    >
                                                                                        <button
                                                                                            onClick={updateInvoiceSize}
                                                                                            disabled={!showSave}
                                                                                            className="new-customer-bottons-button-save"
                                                                                            style={!showSave ? {
                                                                                                backgroundColor: "#3f526698",
                                                                                                color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                                                                cursor: "default"
                                                                                            } : null}
                                                                                        >
                                                                                            <img src={saveIcon} alt="Save" />
                                                                                            {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                : null}
                                                                        </Fragment>
                                                                    }
                                                                </div>
                                                            </div>

                                                            {useMode === "development" ?
                                                                <div ref={shareRef} className="notification-filter-botton-container unselectable">

                                                                    <button
                                                                        style={{
                                                                            marginLeft: "15px",
                                                                            marginBottom: "15px",
                                                                            minWidth: "150px"
                                                                        }}
                                                                        className={`notification-list-config-btn ${appTheme.dark ? `${isTouchEnabled() ? "settings-notification-btn-dark-no-hover" : "settings-notification-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "settings-notification-btn-light-no-hover" : "settings-notification-btn-light"} border-box-light`}`}
                                                                        onClick={onClickShareButton}
                                                                    >
                                                                        <img
                                                                            src={shareIcon}
                                                                            style={{
                                                                                width: "20px",
                                                                                height: "20px",
                                                                                marginRight: "8px"
                                                                            }}
                                                                            alt="Share"
                                                                        />
                                                                        <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Share" : "Compartir"}</p>
                                                                    </button>

                                                                    <span
                                                                        className={`notification-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownShareOpen ? 'active' : 'inactive'}`}
                                                                        style={{
                                                                            marginLeft: "80px",
                                                                            marginTop: "-8px",
                                                                        }}
                                                                    />

                                                                    <div
                                                                        style={{
                                                                            boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                                            marginLeft: "-70px",
                                                                            marginTop: "-4px",
                                                                            width: "220px",

                                                                        }}
                                                                        className={`dropdown-menu-notification ${appTheme.dark ? "dropdown-menu-notification-dark border-box-dark" : "dropdown-menu-notification-light border-box-light"} ${dropDownShareOpen ? 'active' : 'inactive'}`}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                minHeight: "220px",
                                                                                paddingTop: "13px"
                                                                            }}
                                                                        >
                                                                            <div className="drop-men-not-option-con" style={{ display: "flex", justifyContent: "center" }}>
                                                                                <button
                                                                                    className="invent-add-menu-btn"
                                                                                    disabled={loading ? true : false}
                                                                                    onClick={null}
                                                                                    style={{
                                                                                        justifyContent: "flex-start"
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        style={{
                                                                                            width: "20px",
                                                                                            height: "20px",
                                                                                            marginRight: "10px",
                                                                                            marginLeft: "18px"
                                                                                        }}
                                                                                        src={downloadIconDark}
                                                                                        alt="Download"
                                                                                    />
                                                                                    <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Download PDF" : "Descargar PDF"} </p>
                                                                                </button>
                                                                            </div>
                                                                            <div className="drop-men-not-option-con" style={{ display: "flex", justifyContent: "center" }}>
                                                                                <button
                                                                                    className="invent-add-menu-btn"
                                                                                    disabled={loading ? true : false}
                                                                                    onClick={null}
                                                                                    style={{
                                                                                        justifyContent: "flex-start"
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        style={{
                                                                                            width: "20px",
                                                                                            height: "20px",
                                                                                            marginRight: "10px",
                                                                                            marginLeft: "18px"
                                                                                        }}
                                                                                        src={copyIconDark}
                                                                                        alt="Copy"
                                                                                    />
                                                                                    <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Copy link" : "Copiar enlace"} </p>
                                                                                </button>
                                                                            </div>
                                                                            <div className="drop-men-not-option-con" style={{ display: "flex", justifyContent: "center" }}>
                                                                                <button
                                                                                    className="invent-add-menu-btn"
                                                                                    disabled={loading ? true : false}
                                                                                    onClick={() => onClickShareOption("email")}
                                                                                    style={{
                                                                                        justifyContent: "flex-start"
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        style={{
                                                                                            width: "20px",
                                                                                            height: "20px",
                                                                                            marginRight: "10px",
                                                                                            marginLeft: "18px"
                                                                                        }}
                                                                                        src={emailIconDark}
                                                                                        alt="Email"
                                                                                    />
                                                                                    <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Email" : "Correo"} </p>
                                                                                </button>
                                                                            </div>
                                                                            <div className="drop-men-not-option-con" style={{ display: "flex", justifyContent: "center" }}>
                                                                                <button
                                                                                    className="invent-add-menu-btn"
                                                                                    disabled={loading ? true : false}
                                                                                    style={{
                                                                                        marginBottom: "15px",
                                                                                        justifyContent: "flex-start"
                                                                                    }}
                                                                                    onClick={() => onClickShareOption("whatsapp")}
                                                                                >
                                                                                    <img
                                                                                        style={{
                                                                                            width: "20px",
                                                                                            height: "20px",
                                                                                            marginRight: "10px",
                                                                                            marginLeft: "18px"
                                                                                        }}
                                                                                        src={whatsAppIconDark}
                                                                                        alt="WhatsApp"
                                                                                    />
                                                                                    <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "WhatsApp" : "WhatsApp"} </p>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : null}

                                                            <button
                                                                onClick={e => onDelete(e)}
                                                                className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                                disabled={!canDeleteSale()}
                                                                style={canDeleteSale() ? { margin: "0px 0px 15px 15px" } : {
                                                                    margin: "0px 0px 15px 15px",
                                                                    backgroundColor: "#3f526698",
                                                                    color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                                    cursor: "not-allowed"
                                                                }}
                                                            >
                                                                <img src={deleteIcon} alt="Delete" />
                                                                {appLanguage.en ? <p>Delete</p> : <p>Eliminar</p>}
                                                            </button>

                                                            {receiptData && (
                                                                <ReactToPrint
                                                                    trigger={() =>
                                                                        <button
                                                                            onClick={null}
                                                                            disabled={!canPrintInvoice()}
                                                                            className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                                            style={canPrintInvoice() ? { margin: "0px 0px 15px 15px" } : {
                                                                                margin: "0px 0px 15px 15px",
                                                                                backgroundColor: "#3f526698",
                                                                                color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                                                cursor: "not-allowed"
                                                                            }}
                                                                        >
                                                                            <img src={printIconDark} alt="Print" />

                                                                            {appLanguage.en ?
                                                                                <p style={{ whiteSpace: "nowrap" }}>Print {getInvoiceSize()} </p>
                                                                                :
                                                                                <p style={{ whiteSpace: "nowrap" }}>Imprimir {getInvoiceSize()} </p>
                                                                            }
                                                                        </button>
                                                                    }
                                                                    content={() => receiptRef.current}
                                                                />
                                                            )}

                                                            <SaleReceipt receiptData={receiptData} receiptRef={receiptRef} />

                                                        </div>

                                                        {/** Customer and options */}
                                                        <div className="unselectable" style={{ display: "flex", width: "100%", marginBottom: "20px" }}>
                                                            {isCustomerSelected() && customer ?
                                                                <div
                                                                    className="shoppo-cart-cust-card-div"
                                                                    style={{
                                                                        display: "flex",
                                                                        borderRadius: "100px",
                                                                        margin: "1px 10px 0 20px",
                                                                        width: "calc(100% - 40px)",
                                                                        justifyContent: "space-between",
                                                                        height: "36px",
                                                                        minHeight: "36px",
                                                                    }}
                                                                >
                                                                    <div
                                                                        onClick={onOpenCustomer}
                                                                        className={`${appTheme.dark ? (isTouchEnabled() ? "shoppo-cart-cust-card-div-dark-NOHOVER" : "shoppo-cart-cust-card-div-dark-HOVER") : (isTouchEnabled() ? "shoppo-cart-cust-card-div-light-NOHOVER" : "shoppo-cart-cust-card-div-light-HOVER")}`}
                                                                        style={{
                                                                            display: "flex",
                                                                            borderRadius: "100px",
                                                                            paddingLeft: "10px",
                                                                            width: "100%",
                                                                            alignItems: "center",
                                                                            height: "36px",
                                                                            minHeight: "36px",
                                                                        }}
                                                                    >
                                                                        <p
                                                                            style={{
                                                                                "display": "-webkit-box",
                                                                                "WebkitBoxOrient": "vertical",
                                                                                "WebkitLineClamp": "1",
                                                                                "lineClamp": "1",
                                                                                "overflow": "hidden",
                                                                                "textOverflow": "ellipsis"
                                                                            }}
                                                                        >
                                                                            {customer.id} • {customer.name}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div
                                                                    className={`shoppo-cart-cust-card-div ${appTheme.dark ? "shoppo-cart-cust-card-div-dark" : "shoppo-cart-cust-card-div-light"}`}
                                                                    style={{
                                                                        display: "flex",
                                                                        paddingLeft: "10px",
                                                                        borderRadius: "100px",
                                                                        margin: "0px 10px 0 20px",
                                                                        width: "calc(100% - 40px)",
                                                                        height: "36px",
                                                                        minHeight: "36px",
                                                                    }}
                                                                >
                                                                    {loadingCustomer && (customerId) ?
                                                                        <p>{appLanguage.en ? "Loading..." : "Cargando..."}</p>
                                                                        :
                                                                        <p>{appLanguage.en ? "End consumer (default)" : "Consumidor final (predeterminado)"}</p>
                                                                    }

                                                                </div>
                                                            }

                                                        </div>

                                                        <SalesSummaryTable
                                                            onScroll={onScroll}
                                                            headerHeight={desktopView ? (260 + headerHeight) : (218 + headerHeight)}
                                                            results={sale ? sale.items : []}
                                                            onClickLink={onClickOpenProduct}
                                                            dropDownSettingsOpen={dropDownSettingsOpen}
                                                            dropDownShareOpen={dropDownShareOpen}
                                                        />

                                                        {/** Bottons and prices */}
                                                        <div style={{ display: "flex", width: "100%", flexWrap: "nowrap" }}>

                                                            <div
                                                                className="product-details-page-botttons-section unselectable"
                                                                style={{
                                                                    justifyContent: "flex-start",
                                                                    alignItems: "center",
                                                                    flexWrap: "nowrap",
                                                                    margin: "10px 0px 0px 20px",
                                                                }}
                                                            >
                                                                {sale && (sale.status === "pending") ?
                                                                    <button
                                                                        onClick={onCollectPayment}
                                                                        className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                                        style={{
                                                                            margin: "10px 0px 10px 0px",
                                                                            height: "40px",
                                                                            minWidth: pageWidth > 400 ? "150px" : "100px"
                                                                        }}
                                                                    >
                                                                        <img src={dollarSighDarkIcon} alt="Charge" />

                                                                        {appLanguage.en ? "Charge" : "Cobrar"}
                                                                    </button>
                                                                    : null}

                                                            </div>

                                                            <div style={{ margin: "20px" }}>
                                                                <table cellPadding="10" cellSpacing="8">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", whiteSpace: "nowrap" }}>Subtotal: </td>
                                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "bold", whiteSpace: "nowrap", minWidth: "100px" }}>$ {formatRationalNumber(sale.subtotal)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", whiteSpace: "nowrap", }}>Desc{sale.discount ? (sale.discount.type === "percentage" ? ` ${sale.discount.value}%` : "") : ""}: </td>
                                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "bold", whiteSpace: "nowrap", minWidth: "100px" }}>$ {formatRationalNumber(sale.desc)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", whiteSpace: "nowrap" }}>ITBIS: </td>
                                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "bold", whiteSpace: "nowrap", minWidth: "100px" }}>$ {formatRationalNumber(sale.itbis)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ textAlign: "right", fontSize: "18px", fontWeight: "400", whiteSpace: "nowrap" }}>Total: </td>
                                                                            <td style={{ textAlign: "right", fontSize: "18px", fontWeight: "bold", whiteSpace: "nowrap", minWidth: "100px" }}>$ {formatRationalNumber(sale.total)}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        : null)
                                    )
                                }
                            </Fragment> : null}
                        </div>
                    : null}
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default SaleViwePage;