import { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from '../../context/AppContext';
import { Helmet } from 'react-helmet-async';
import { SideMenuContext } from "../../context/SideMenuContext";
import { AuthContext } from "../../firebase/context";
import firebase from "../../firebase";

import SettingsDespCard from "../../components/settings-desp-card/settingsDespCard";

import SettingsHeader from "../../components/settings-header/settingsHeader";
import SettingsTitleBus from "../../components/settings-title-bus/settingsTitleBus";
import SettingsOption from "../../components/settings-options/settingsOption";

import settingIconDark from '../../icons/settings-dark.png';
import settingIconLight from '../../icons/settings-light.png';

import signatureIconDark from '../../icons/signature-dark.png';
import signatureIconLight from '../../icons/signature-light.png';

import usersIconDark from '../../icons/users-dark.png';
import usersIconLight from '../../icons/users-light.png';

import credictCardIconDark from '../../icons/credit-card-dark.png';
import credictCardIconLight from '../../icons/credit-card-light.png';

import billIconDark from '../../icons/bill-dark.png';
import billIconLight from '../../icons/bill-light.png';

import brokenIconDark from '../../icons/broken-link-dark.png';
import brokenIconLight from '../../icons/broken-link-light.png';

import templateIconDark from "../../icons/template-dark.png";
import templateIconLight from "../../icons/template-light.png";

import businessIcon from '../../img/analysis.svg';
import { ref, getDownloadURL } from "firebase/storage";

// import historyIconDark from "../../icons/history-dark.png";
// import historyIconLight from "../../icons/history-light.png";

import './businessAccount.page.scss';

const BusinessAccountPage = () => {
    const { appTheme, appLanguage } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);
    const { accountData, userData, adminAccess, managerAccess, saveKeyInIndexedDB, adminData, setAdminData } = useContext(AuthContext);

    const descpTextEn = "You are an authorized user on this account, only administrators can make changes.";
    const descpTextEs = "Eres un usuario autorizado en esta cuenta, solo los administradores pueden realizar cambios.";

    const [admin, setAdmin] = useState(adminData ? adminData : {
        name: appLanguage.en ? "Loading..." : "Cargando...",
        email: "",
        pictureLocation: null,
        urlPicture: null,
    });

    useEffect(() => {
        setAdmin(adminData ? adminData : {
            name: appLanguage.en ? "Loading..." : "Cargando...",
            email: "",
            pictureLocation: null,
            urlPicture: null,
        });

        if (adminData) {
            if (adminData.pictureLocation) {
                getPictureFile(adminData.pictureLocation);
            }
        }
        
        // eslint-disable-next-line
    }, [adminData]);

    const getPictureFile = async (path) => {
        // This is a recursive function that download all the dimensions 
        // of a picture store in firebase store
        try {
            const locationPath = `${path}${"_140x140"}`;
            const imageRef = ref(firebase.storage, locationPath);
            const url = await getDownloadURL(imageRef);
            const xhr = new XMLHttpRequest();

            xhr.responseType = 'blob';
            xhr.onload = () => {
                const blob = xhr.response;
                const fr = new FileReader();
                fr.readAsDataURL(blob);
                fr.addEventListener('load', () => {
                    const urlData = fr.result;
                    setAdminData({
                        ...adminData,
                        urlPicture: urlData,
                    });
                    saveKeyInIndexedDB("adminData", {
                        ...adminData,
                        urlPicture: urlData,
                    });
                });
            };
            xhr.open('GET', url);
            xhr.send();
        } catch (error) {
            console.error(error);
        }
    }

    async function getAccountAdminById() {
        try {
            let authUserDocId = null;

            userData.businessAccounts.forEach(ref => {
                if (ref.id === accountData.id) {
                    authUserDocId = ref.doc;
                }
            });

            const response = await firebase.useGetAccountAdminById({
                "authUserDocId": authUserDocId,
                "adminId": accountData.admin,
                "accountId": accountData.id
            });
        
            setAdminData({
                ...adminData,
                name: response.name,
                email: response.email,
                pictureLocation: response.pictureLocation,
            });

            saveKeyInIndexedDB("adminData", {
                ...adminData,
                name: response.name,
                email: response.email,
                pictureLocation: response.pictureLocation,
            });
        } catch (error) {
            console.warn(error);
        }
    }

    useEffect(() => {
        if (!(adminAccess || managerAccess)) {
            getAccountAdminById();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Business Account' : 'Cuenta de negocios'} </title>
            </Helmet>
            <div
                className={`page-business-account-container ${appTheme.dark ? 'dark-text' : 'light-text'}`}
                style={{
                    colorScheme: appTheme.dark ? "dark" : "light"
                }}
            >
                <div className="page-business-account-item">
                    {desktopView ?
                        <SettingsHeader
                            icon={appTheme.dark ? settingIconDark : settingIconLight}
                            text={appLanguage.en ? "Business account" : "Cuenta de negocios"}
                        />
                        : null}
                    <div className="settings-options-container" >
                        <div className="settings-options-container-item">
                            <SettingsTitleBus
                                icon={businessIcon}
                                firstText={accountData ? accountData.businessName : null}
                                secondText={`${appLanguage.en ? "Account" : "Cuenta"} ${accountData ? accountData.id : null}`}
                            />
                            <br />
                            {(adminAccess || managerAccess) ? <Fragment>
                                <SettingsOption
                                    description={appLanguage.en ? "Authorized users" : "Usuarios autorizados"}
                                    icon={appTheme.dark ? usersIconDark : usersIconLight}
                                    goTo={"/usuarios-autorizados"}
                                />
                                {/**<SettingsOption
                                    description={appLanguage.en ? "Account activity" : "Actividad de la cuenta"}
                                    icon={appTheme.dark ? historyIconDark : historyIconLight}
                                    goTo={"/actividad-de-la-cuenta"}
                                />*/}
                                <SettingsOption
                                    description={appLanguage.en ? "Receipt template" : "Plantilla de recibos"}
                                    icon={appTheme.dark ? templateIconDark : templateIconLight}
                                    goTo={"/plantilla-de-recibos"}
                                /> 
                                <SettingsOption
                                    description={appLanguage.en ? "Manage your subscription" : "Gestionar tu suscripción"}
                                    icon={appTheme.dark ? credictCardIconDark : credictCardIconLight}
                                    goTo={"/suscripcion"}
                                />
                                <SettingsOption
                                    description={appLanguage.en ? "Edit account" : "Editar cuenta"}
                                    icon={appTheme.dark ? signatureIconDark : signatureIconLight}
                                    goTo={"/editar-cuenta"}
                                />
                                <SettingsOption
                                    description={appLanguage.en ? "Bills" : "Facturas"}
                                    icon={appTheme.dark ? billIconDark : billIconLight}
                                    goTo={"/facturas"}
                                />
                            </Fragment> : <Fragment>
                                <SettingsDespCard
                                    text={appLanguage.en ? descpTextEn : descpTextEs}
                                    admin={admin}
                                />
                                <SettingsOption
                                    description={appLanguage.en ? "Leave account" : "Abandonar cuenta"}
                                    icon={appTheme.dark ? brokenIconDark : brokenIconLight}
                                    goTo={"/abandonar-cuenta"}
                                />
                            </Fragment>}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default BusinessAccountPage;