
import { useContext, useEffect, useRef } from "react";
import { AppContext } from "../../../context/AppContext";

import { ShareViaExternalAppContext } from "./shareViaExternalAppContext";

import closeLightIcon from '../../../icons/cross.png';
import closeDarkIcon from '../../../icons/cross-white.png';

import emailIcon from "../../../img/email.svg";
import whatsappIcon from "../../../img/whatsapp.svg";

import sendIconDark from "../../../icons/send-dark.png";
import { SoftLoadingContext } from "../../../components/soft-loading/softLoadingContext";
import { AuthContext } from "../../../firebase/context";

const ShareViaExternalApp = ({ pickerWidth, sale, customer }) => {
    const { appTheme, appLanguage, formatRationalNumber } = useContext(AppContext);
    const { setSoftLoadingActive } = useContext(SoftLoadingContext);
    const { accountData } = useContext(AuthContext);

    const {
        shareViaExternalAppActive,
        contactText,
        contactType,
        contactTextAlert,
        setShareViaExternalAppActive,
        setContactText,
        setContactTextAlert,
    } = useContext(ShareViaExternalAppContext);

    let menuRef = useRef();

    useEffect(() => {
        if (shareViaExternalAppActive) {
            let handler = (e) => {
                if (!menuRef.current.contains(e.target)) {
                    setShareViaExternalAppActive(false);
                };
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const onClickClose = () => {
        setShareViaExternalAppActive(false);
    }

    const onChangeContactText = (e) => {
        setContactTextAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        if (customer && contactType) {

            const text = e.target.value;

            if (contactType === "whatsapp") {
        
                let phoneFormatted = '';
    
                for (let i = 0; i < text.length; i++) {
                    if(i === 0) {
                        if (text[i] === "+" || ( !( isNaN(text[i]) ) ) ) {
                            phoneFormatted += text[i]; 
                        }
                    } else {
                        if( (!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                            phoneFormatted += text[i];
                        }
                    }
                }

                if (phoneFormatted === "") {
                    setContactText("");
                } else {
                    if (phoneFormatted[0] === "+") {
                        setContactText(phoneFormatted);
                    } else {
                        if(phoneFormatted.trim().length > 0 ) {
                            setContactText("+1"+ phoneFormatted);
                        }
                    }
                }
            } else {
                setContactText(text.trim());
            }
        }
    }

    const validateEmail = (email) => {
        return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const invalidPhone = (text) => {
        if (text.length < 10 ) {
            return true;
        } else {
            return false;
        }
    }

    const getPhoneError = (phone) => {
        if (phone.trim() !== "") {
            if (invalidPhone(phone.trim())) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
        
    const getEmailError = (email) => {
        if (email.trim() !== "") {
            if(!validateEmail(email.trim())) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onSendMsg();
        }
    }

    const blinkSoftLoading = () => {
        setSoftLoadingActive(true);
        setTimeout(() => {
            setSoftLoadingActive(false);
        }, 600);
    }

    const onSendMsg = () => {
        if (contactType) {

            const invoiceNotes = encodeURIComponent(sale.invoice.footer);
            const linkTest = `${accountData.businessName}%20%0A%0AResumen%20de%20venta%3A%0A%0ASubtotal%3A%20${formatRationalNumber(sale.subtotal)}%0ADesc%3A%20${formatRationalNumber(sale.desc)}%0AItbis%3A%20${formatRationalNumber(sale.itbis)}%0ATotal%3A%20${formatRationalNumber(sale.total)}%0A%0AVer%20factura%3A%20https%3A%2F%2Fhazcuentas.com%2F7jylL5MUM4NcYlrx3c1UsBBy9dO2%0A%0A${invoiceNotes}%20`;

            if (contactType === "whatsapp") {

                if (getPhoneError(contactText)) {
                    setContactTextAlert({
                        alert: true,
                        enText: ' Phone is not valid',
                        esText: ' El número de teléfono no es válido'
                    });
                    return;
                }

                let whatsappVariable = null;

                if (contactText === "") {
                    whatsappVariable = `whatsapp://send?text=`;
                } else {
                    const cleanedNumber = contactText.replace("+", "");
                    whatsappVariable = `whatsapp://send?phone=${cleanedNumber}&text=`;
                }

                const link = `${whatsappVariable}${linkTest}`;

                blinkSoftLoading();
                window.location.href = link;

            } else {

                if (getEmailError(contactText)) {
                    setContactTextAlert({
                        alert: true,
                        enText: ' Email is not valid',
                        esText: ' El correo no es válido'
                    }); 
                    return;
                }

                const link = `mailto:${contactText}?subject=${encodeURIComponent(`Factura de ${accountData.businessName}`)}&body=${linkTest}`;

                blinkSoftLoading();
                window.location.href = link;
            } 
        }
    }

    const getInputLabel = () => {
        if (contactType === "whatsapp") {
            return appLanguage.en ? "WhatsApp number" : "Número de WhatsApp";
        } else {
            return appLanguage.en ? "Email" : "Correo electrónico";
        }
    }

    const getInputPlaceholder = () => {
        if (contactType === "whatsapp") {
            return appLanguage.en ? "Enter the WhatsApp number..." : "Introduzca número de WhatsApp...";
        } else {
            return appLanguage.en ? "Enter email..." : "Introduzca el correo...";
        }
    }

    const getContactDescription = () => {
        if (contactType === "whatsapp") {
            return appLanguage.en ? "To select the contact from WhatsApp, leave the field empty." : "Para seleccionar el contacto desde WhatsApp, deja el campo vacío.";
        } else {
            return appLanguage.en ? " To select the contact from the email App, leave the field empty." : "Para seleccionar el contacto desde la App de correo electrónico, deja el campo vacío.";
        }
    }

    useEffect(() => {
        if (customer && shareViaExternalAppActive && contactType) {
            if (contactType === "whatsapp") {
                setContactText(customer.phone);
            } else {
                setContactText(customer.email);
            }
        }
    // eslint-disable-next-line
    }, [customer, shareViaExternalAppActive, contactType]);

    return (
        <div
            style={{
                WebkitUserSelect: "auto",
                WebkitTouchCallout: "auto",
                MozUserSelect: "auto",
                MsUserSelect: "auto",
                userSelect: "auto",
            }}
        >
            {shareViaExternalAppActive ? <div className='picker-date-lock-screen' style={{ width: pickerWidth, minHeight: "calc(100vh - 65px)" }} /> : null}
            <div ref={menuRef} className={`picker-date-genaral-card ${shareViaExternalAppActive ? 'active' : 'inactive'}`} style={{ marginLeft: `calc(${pickerWidth / 2}px - 175px)`, marginTop: "45px", background: appTheme.dark ? "#555" : "white", color: appTheme.dark ? "white" : "black" }}>
                {sale && contactType ?
                    <div className='picker-date-general-despcrition' style={{ display: "flex", minHeight: "280px" }}>

                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px" }}>
                            <h4 style={{ marginLeft: "15px" }}>
                                {contactType === "whatsapp" ?
                                    <span style={{ fontWeight: "500" }}>{appLanguage.en ? "Share on WhatsApp" : "Compartir por WhatsApp"}:</span>
                                    :
                                    <span style={{ fontWeight: "400" }}>{appLanguage.en ? "Share by email" : "Compartir por correo electrónico"}</span>
                                }
                            </h4>

                            <button
                                onClick={onClickClose}
                                className={`see-details-transations-view unselectable ${appTheme.dark ? 'dar-box-sha-none-hover' : 'lig-box-sha-none-hover'}`}
                            >
                                <img className="see-details-transations-img" src={appTheme.dark ? closeDarkIcon : closeLightIcon} alt="close" />
                            </button>

                        </div>

                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <img
                                src={contactType === "whatsapp" ? whatsappIcon : emailIcon}
                                alt="Contact type"
                                style={{
                                    width: "50px",
                                    height: "50px",
                                    marginBottom: "15px"
                                }}
                            />

                            <div style={{ margin: "8px 15px", width: "calc(100% - 30px)" }}>
                                <p>{getInputLabel()}</p>
                                <input
                                    value={contactText}
                                    onChange={(e) => onChangeContactText(e)}
                                    onKeyDown={e => handleKeyPress(e)}
                                    inputMode={contactType === "whatsapp" ? "numeric" : "text"}
                                    autoComplete="off"
                                    autoCorrect="off"
                                    maxLength={50}
                                    placeholder={getInputPlaceholder()}
                                    style={{
                                        colorScheme: appTheme.dark ? "dark" : "light",
                                        background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                        color: appTheme.dark ? "white" : "black"
                                    }}
                                    className={`cash-in-cash-reg-input ${contactTextAlert.alert ? "cash-in-cash-reg-input-alert" : (appTheme.dark ? "cash-in-cash-reg-input-dark" : "cash-in-cash-reg-input-light")}`}
                                />

                                <p style={{fontSize: "14px", marginTop: "10px"}}>{getContactDescription()}</p>
                            </div>

                            <div className="unselectable" style={{ display: "flex", flexDirection: "column" }}>
                                <div
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginTop: "40px",
                                        marginBottom: "30px"
                                    }}
                                >
                                    <button
                                        onClick={onSendMsg}
                                        className="cash-in-cash-reg-bottons-button-save"
                                        style={{ width: "190px", maxWidth: "190px" }}
                                    >
                                        <img src={sendIconDark} style={{ marginRight: "10px" }} alt="Send invoice" />
                                        {appLanguage.en ? <p>Send</p> : <p>Enviar</p>}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                : null}
            </div>
        </div>
    )
}

export default ShareViaExternalApp;